.comboCard {
    background-position: center;
    background-size: cover;
    background-color: rgb(255, 255, 255, .75);
    box-shadow: 0px 18px 25px 0 rgba(255, 255, 255, 0.25);
    border: 2px solid gray;
    border-radius: 20px;
    height: 30vh;
    max-width: 85vh;
    padding: 20px;
    position: relative;
    width: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .comboIntroCard {
    background-position: center;
    background-size: cover;
    background-color: seashell;
    border: 2px solid gray;
    box-shadow: 0px 18px 25px 0 rgba(255, 255, 255, 0.25);
    
    border-radius: 20px;
    /* height: 25vh; */
    max-width: 85vh;
    padding: 20px;
    position: relative;
    width: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .comboCard > h3 {
    position: absolute;
    bottom: 10px;
    /* color: white; */
  }
  
  .comboCard__container {
    display: flex;
    justify-content: center;
    margin-top: 5vh;
    height: 65vh;
  }
  
  .comboProfile_container {
    display: flex;
    position: absolute;
    align-content: space-between;
  }
  
  .comboCard__greeting {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .greetingText {
    padding-bottom:1vh
  }
  .greetingTextSmall {
    display: flex;
    justify-content: center;
    padding-bottom:.5vh
  }
  
  .comboCard__userAvatar {
    width: 17vh;
    /* height: 17vh; */
    border-radius: 75px;
    padding-bottom: 1vh;
  }
  
  .comboCard__buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .comboIntro_button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vh 0;
  }