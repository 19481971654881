.chartsCard {
    margin-bottom: 2rem;
    padding: 10px;
  }
  .BYOB {
    display: flex;
    flex-direction: column;
  }
  .BYOB__action {
    display: flex;
    justify-content: space-between;
    /* flex-direction: column; */
  }
  .chart {
    display: flex;
    /* background-color: white; */
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 5vh;
    /* flex-direction: column; */
    align-items: center;
    max-width: 150vh;
    margin: auto;
    height: 75vh;

}
  .chart__item {
    width: 125vh;
    margin: 0 1vh;
    background-color: white;
    border-radius: 2vh;
    border: 2px solid gray;
    padding: 1rem;
}
  /* .chartCard {
      background-position: center;
      background-size: cover;
      background-color: rgb(255, 255, 255, .75);
      box-shadow: 0px 18px 25px 0 rgba(255, 255, 255, 0.25);
      border: 2px solid gray;
      border-radius: 20px;
      height: 30vh;
      max-width: 85vh;
      padding: 20px;
      position: relative;
      width: 40vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    } */
    .chartIntroCard {
      background-position: center;
      background-size: cover;
      background-color: seashell;
      border: 2px solid gray;
      box-shadow: 0px 18px 25px 0 rgba(255, 255, 255, 0.25);
      
      border-radius: 20px;
      /* height: 25vh; */
      max-width: 85vh;
      padding: 20px;
      position: relative;
      width: 40vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    
    .chartCard > h3 {
      position: absolute;
      bottom: 10px;
      /* color: white; */
    }
    h1 {
      margin: 1.5vh 0;
      /* color: white; */
    }
    
    .chartCard__container {
      display: flex;
      justify-content: center;
      padding: 2vh;
      border-radius: 20px;
      border: 2px solid gray;
      background-color: white;
      /* margin-top: 5vh; */
      flex-direction: column;
      align-items: center;
      max-width: 150vh;
      margin: auto;
      /* height: 65vh; */
    }
    
    .chartProfile_container {
      display: flex;
      position: absolute;
      align-content: space-between;
    }
    
    .chartCard {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .greetingText {
      padding-bottom:1vh
    }
    .chartTitle {
      display: flex;
      justify-content: center;
    }
    
    .chartCard__userAvatar {
      width: 17vh;
      /* height: 17vh; */
      border-radius: 75px;
      padding-bottom: 1vh;
    }
    
    .chartCard__buttons {
      display: flex;
      justify-content: space-between;
    }
    
    .chartIntro_button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1vh 0;
    }