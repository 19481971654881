.productCard {
    margin-bottom: 2rem;
    padding: 10px;
  }
.productsCard {
    background-position: center;
    background-size: cover;
    background-color: rgb(255, 255, 255, .75);
    box-shadow: 0px 18px 25px 0 rgba(255, 255, 255, 0.25);
    border: 2px solid gray;
    border-radius: 20px;
    height: 30vh;
    max-width: 85vh;
    padding: 20px;
    position: relative;
    width: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .productsIntroCard {
    background-position: center;
    background-size: cover;
    background-color: seashell;
    border: 2px solid gray;
    box-shadow: 0px 18px 25px 0 rgba(255, 255, 255, 0.25);
    
    border-radius: 20px;
    /* height: 25vh; */
    max-width: 85vh;
    padding: 20px;
    position: relative;
    width: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .form--product {
    margin-top: 1vh;
  }
  .productsCard > h3 {
    position: absolute;
    bottom: 10px;
    /* color: white; */
  }
  .productsCard__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    /* flex-direction: column; */
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 100vh;
    /* height: 65vh; */
  }
  .productsCard__container2 {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    /* flex-direction: column; */
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 100vh;
    /* height: 65vh; */
  }
  
  
  .productsProfile_container {
    display: flex;
    position: absolute;
    align-content: space-between;
  }
  .form--product {
    display: flex;
    flex-direction: column;
    margin: 10vh auto 0;
    text-align: left;
    max-width: 65vh;
    /* height: 30vh; */
}
  .products {
    display: flex;
    flex-direction: column;
    width: 80vh;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

  }
  .productsCard__greeting {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .greetingText {
    padding-bottom:1vh
  }
  .greetingTextSmall {
    display: flex;
    justify-content: center;
    padding-bottom:.5vh
  }
  .productForm {
    max-width: 65vh;
  }
  .productForm__upload {
    margin-top: 4vh;
  }
  .productsCard__userAvatar {
    width: 17vh;
    /* height: 17vh; */
    border-radius: 75px;
    padding-bottom: 1vh;
  }
  
  .productsCard__buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .productsIntro_button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vh 0;
  }