.h1, h1 {
    font-size: 2.5rem;
    margin: 1vh 0;
}
h2 {
    font-size:4.59vh;
    margin: 1vh 0;
}
h3 {
    font-size: 3vh;
    margin: 0 0 1vh;
}
fieldset {
    min-width: 0;
    padding: .5vh 0;
    margin: 0;
    border: 0;
}
.loginCard {
    margin-bottom: 2rem;
    /* width: 35vh; */
    padding: 10px;
    max-width:55vh;
  }
  .loginCard__container {
    display: flex;
    justify-content: center;
    margin-top: 5vh;
    flex-direction: column;
    align-items: center;
    /* height: 65vh; */
  }
.form--login {
    display: flex;
    flex-direction: column;
    margin: 10vh auto 0;
    text-align: left;
    max-width: 65vh;
    /* height: 30vh; */
}

.form--login > fieldset > input[type="email"],
.form--login > fieldset > input[type="password"] {
    width: 25em;
}

.label--login {
    width: 8rem;
    display: inline-block;
}

.form-control {
    display: block;
    width: 94%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.container--login {
    text-align: center;
}

.form--login {
    background-size: cover;
    background-position-x: center;
    border-radius: 20px;
    border: 2px solid gray;
    box-shadow: 0px 18px 25px 0 rgba(255, 255, 255, 0.25);
    z-index: 1;
    min-height: 20rem;
    padding: 0 1rem 1rem;
    background-color: rgba(255, 255, 255, 0.75);
    background-blend-mode: overlay;
    background-repeat: no-repeat;
}

.button--close {
    position: relative;
    bottom: -2rem;
}

.dialog {
    min-width: 15rem;
    min-height: 5rem;
}
